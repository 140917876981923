import React from "react";
import Banner from "../components/Banner";
import WhatServiceDo from "../components/service/WhatServiceDo";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Data Visualization Agency`,
    content:
      "Our data visualization agency uses advanced dashboards and visualization techniques to transform data into interactive stories, helping businesses seize growing opportunities.",
  };
  const whatServiceContent = {
    title: "What does Data Visualization Company do?",
    content: [
      "Dashboard and data visualization companies simplify complex data into visually interactive and meaningful representations.",
      "They use design principles and methodologies, industrial expertise, and cutting-edge technologies to provide valuable insights from the data.",
      "Additionally, data visualization consultancy plays a pivotal role in unlocking your business potential through data integration, data analytics, continuous support, etc.",
      "They work closely with you to understand your requirements for creating customized solutions.",
      "These agencies help you make informed decisions, identify market trends, and drive sustainable business growth.",
    ],
    img: ["what-service-image-data-visual.webp"],
    imageTitle: 'Data Visualisation',
    imageAlt: '',
  };

  const commonContent = {
    title: "Which Industries Need Data Vizualisation Agency?",
    para: "Data visualization as a service is an indispensable part of every business seeking to harness data’s potential to make informed decisions by tracking performance and identifying market trends.",
    img: ["who-needs-data-visual.webp"],
    imageTitle: 'Data Visualisation Services',
    imageAlt: '',
    content: [
      {
        title: "Wellness Companies",
        text: "Companies looking for continuous tracking of their performance metric to empower data-driven informed decision-making. ",
      },
      {
        title: "Consumer Companies",
        text: "Companies whose main goals are to gain insights into consumer behavior and market trends to tap into the market opportunities. ",
      },
      {
        title: "Financial Institutions",
        text: "Companies whose aims are to simplify complex data, visualize trends, mitigate risk, and achieve sustainable business growth.",
      },
      {
        title: "Logistic Companies",
        text: "Companies looking to streamline their complex data and analyze the performance metrics for efficient resource allocation.",
      },
    ],
  };

  const commonContent2 = {
    title:
      "How Data Vizualisation & Services Benefit Business?",
    para: "Dashboard and data visualization services improve efficiency and uncover growth opportunities for businesses, enabling them to monitor their operations and make data-driven decisions.",
    img: ["what-are-benefits-data-visual.webp"],
    imageTitle: 'Dashboards & Data Visualisation Benefits',
    imageAlt: '',
    content: [
      {
        title: "Live Monitoring",
        text: "Data Visualization provides real-time metrics and performance indicators to help your business make prompt and proactive decisions.",
      },
      {
        title: "Performance Analyses",
        text: "Dashboard and data visualization as a service provide suggestions on improving your business for increased efficiency and productivity.",
      },
      {
        title: "Data-Driven Decisions",
        text: "Data visualization helps your business make informed decisions, plan strategies, and gain a competitive edge through insights derived from data analyses. ",
      },
      {
        title: "Increased Efficiency",
        text: "Data visualization reduces the manual work of simplifying data, eliminates redundancies, and helps make informed decisions.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why Opt Octet as Your Data <span class='h1-span'>Visualization Company?</span>",
    linkText: ``,
    para: "At Octet, our approach as data visualization experts is to simplify and make complex data engaging. We aim to offer an unparalleled solution that drives maximum value to your business by leveraging modern technology, creativity, and industrial knowledge.",
    img: ["why-opt-for-data-visual.webp"],
    imageTitle: 'Data Visualisation Company',
    imageAlt: '',
    content: [
      {
        title: "Interactive Interface",
        text: "We focus on a visually appealing, interactive interface through seamless interaction and data exploration to enhance user engagement in your digital products.",
      },
      {
        title: "Optimised Performance",
        text: "We aim to optimize data to provide real-time performance insights into your digital offerings for better decision-making capabilities.",
      },
      {
        title: "Seamless Integration",
        text: "We integrate dashboards and data visualizations into your existing systems to ensure a smooth, unified user experience.",
      },
      {
        title: "Proactive Suggestions",
        text: "We have worked with varied industries and offer our expertise to help you uncover market trends. ",
      },
    ],
  };
  const commonContent4 = {
    title: "How do we offer Data Visualization services?",
    para: "Our data visualization consultants leverage modern tools and techniques, industry expertise, and streamline processes to create intuitive and attractive dashboards to simplify your complex data.",
    img: ["how-we-conduct-data-visual.webp"],
    imageTitle: 'Dashboards & Data Visualisation Process',
    imageAlt: '',
    content: [
      {
        title: "1. Define user goals",
        text: "We start by defining the expectation of the users from the data, and how it can best fulfil their needs.",
      },
      {
        title: "3. Building data architecture",
        text: "We organize the data logically and decide on the level of drill-downs and filters, pagination, and data sources.",
      },
      {
        title: "2. Understand data & studies",
        text: "We gather in-depth information about all the data points covered in the product and their inter-relationships.",
      },
      {
        title: "4. Visualising data",
        text: "We finalize the first view of the dashboard and create designs of charts and graphs based on various data types.",
      },
    ],
  };
  const commonContent5 = {
    title: "Why Invest in Data Visualization Company?",
    para: "Data visualization agency improves productivity, enhance the decision-making process, and help to gain a competitive edge in the data-driven market.",
    content: [
      {
        title: "24% Increased Sales",
        text: "Dashboard and data visualization help you make informed decisions through visual insights, resulting in higher sales.",
      },
      {
        title: "97% User Trust",
        text: "Dashboard and data visualization assist businesses in gaining customers' trust by understandably offering data.",
      },
      {
        title: "44% Sales Productivity",
        text: "Data visualization monitors and offers real-time team performance, resulting in improved sales productivity.",
      },
      {
        title: "30% Customer Satisfaction",
        text: "Dashboard and data visualization offer visually attractive and understandable customer data that fulfill their requirements.",
      },
    ],
    desc: "**These data are from public sources; we do not claim to owe these stats.",
  };
  const cta = {
    title: "Simplify Complexity with Data Visualization Experts",
  };
  const cta2 = {
    title: "Visualize Your Success with Data Visualization Experts",
  };
  const cta3 = {
    title:
      "We help you gain insights at a glance",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
      title: "Visa",
      desc: "A Campaign Monitoring Platform",
      Industry: "Enterprise",
      link: '/project/campaign-monitoring-platform-design/',
      firstAlt: "",
      firstTitle: "Projects Overview",
      secondAlt: "",
      secondTitle: "Dashboard",
      WhatWeDid: [
        {
          title: 'User Research',
          link: '/user-research/'
        },
        {
          title: 'UX Consultation',
          link: '/ux-consulting/'
        },
        {
          title: 'UI UX Designing',
          link: '/ui-ux-designing/'
        },
      ],
      images: ["visa-work.webp", "visa-work-2.webp"],
    },
    {
      title: "Style Spot",
      desc: "An E-Commerce Platform",
      Industry: "Enterprise",
      link: '/project/ui-ux-design-style-spot/',
      WhatWeDid: [
        {
          title: 'UX Consultation',
          link: '/ux-consulting/'
        },
        {
          title: 'UI UX Designing',
          link: '/ui-ux-designing/'
        },
        {
          title: 'UI Development',
          link: '/ui-development/'
        },
      ],
      images: ["shoppers-stop.webp", "shoppers-stop-2.webp"],
      firstAlt: "",
      firstTitle: "Logistics Performance",
      secondAlt: "",
      secondTitle: "Service Provider Details",
    },
  ];

  const Questions = [
    "In which area can I apply data and dashboard visualization?",
    "How do you design interactive dashboards?",
    "Do your data visualization consultants help in live performance monitoring?",
    "Can your dashboard and data visualization monitor KPIs?",
    "What is the duration of your data visualization services?",
    "How much do you charge for data visualization services?",
    "How can I start with your dashboard and data visualization services?",
  ];
  const clientsTitle =
    "Companies for whom we visualized data";
  const faqDes = `We understand the queries you might face while looking for our data visualization services. In the following questions, we tried to give you more information about our data visualization agency. However, if you still have any questions, please <a href="/contact-us/" title='Contact Us'>contact us</a>.`;
  
  const faqData = [
    {
      para: [
        `Dashboard and data visualization can be used across multiple industries to analyze complex data. They can be used in finance, sales, marketing, healthcare, and many other industries.`,
        `You can use data visualization services wherever you need to gain data-driven insights to make informed decisions and improve business performance.`,
      ],
    },
    {
      para: [
        `We use several best practices in dashboard visualization to ensure enhanced user experience.`,
        `We clearly define the project's purpose, consider users' preferences, and unclutter the layout to present the data in a visually interactive and simplified manner.`,
        `Through data visualization as a service, we aim to facilitate data exploration and enable you to drive valuable data-driven insights.`
      ],
    },
    {
      para: [
        `Yes, our data visualization consultants help you analyze performance and monitor key performance metrics in real-time by visualizing the real-time data through interactive layouts, charts, etc.`,
        `As a data visualization company, we provide a customized and dynamic view to help your business track live data, identify growing market trends through simplified data, and tap new opportunities.`,
      ],
    },
    {
      para: [
        `Yes, you can monitor key performance indicators through our dashboard and data visualization. We offer a visually appealing, interactive, and easily understandable format for tracking performance.`,
        `As a data visualization agency, we have access to the tools, technologies, and other resources to help you gain business insights, identify areas for improvement, and make informed decisions through optimized performance.`,
      ],
    },
    {
      para: [
        `The duration of our dashboard and data visualization services varies depending on various factors, such as the scope and complexity of the project, project requirements, and technologies used.`,
        `It also depends on the number of dashboards required to serve your business purpose. Hence, we request you contact us to discuss the exact timelines.`,
      ],
    },
    {
      para: [
        `The cost of our dashboard and data visualization services varies depending on certain factors such as the complexity of the project, the number of dashboards made, customizations required, technologies used, etc.`,
        `Hence, we would request you to <a href='/contact-us/' tile="contact us">contact us</a> for a detailed quotation.`
      ],
    },
    {
      para: [
        `In order to start with our data visualization service, you just have to submit your requirements and details on the <a href='/contact-us/' title="contact us">contact us</a> form.`,
        `Our team will then contact you to discuss your business goals and service requirements.`
      ],
    },
  ];

  return (
    <>
      
    <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "1. In which area can I apply data and dashboard visualization?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Dashboard and data visualization can be used across multiple industries to analyze complex data. They can be used in finance, sales, marketing, healthcare, and many other industries. You can use data visualization services wherever you need to gain data-driven insights to make informed decisions and improve business performance."
                }
              },{
                "@type": "Question",
                "name": "2. How do you design interactive dashboards?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "experience. 
            
            We clearly define the project's purpose, consider users' preferences, and unclutter the layout to present the data in a visually interactive and simplified manner. 
            
            Through data visualization as a service, we aim to facilitate data exploration and enable you to drive valuable data-driven insights."
                }
              },{
                "@type": "Question",
                "name": "3. Do your data visualization consultants help in live performance monitoring?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, our data visualization consultants help you analyze performance and monitor key performance metrics in real-time by visualizing the real-time data through interactive layouts, charts, etc. 
            
            As a data visualization company, we provide a customized and dynamic view to help your business track live data, identify growing market trends through simplified data, and tap new opportunities."
                }
              },{
                "@type": "Question",
                "name": "4. Can your dashboard and data visualization monitor KPIs?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, you can monitor key performance indicators through our dashboard and data visualization. We offer a visually appealing, interactive, and easily understandable format for tracking performance. 
            
            As a data visualization agency, we have access to the tools, technologies, and other resources to help you gain business insights, identify areas for improvement, and make informed decisions through optimized performance."
                }
              },{
                "@type": "Question",
                "name": "5. What is the duration of your data visualization services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The duration of our dashboard and data visualization services varies depending on various factors, such as the scope and complexity of the project, project requirements, and technologies used. 
            
            It also depends on the number of dashboards required to serve your business purpose. Hence, we request you contact us to discuss the exact timelines."
                }
              },{
                "@type": "Question",
                "name": "6. How much do you charge for data visualization services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The cost of our dashboard and data visualization services varies depending on certain factors such as the complexity of the project, the number of dashboards made, customizations required, technologies used, etc. Hence, we would request you to contact us for a detailed quotation."
                }
              },{
                "@type": "Question",
                "name": "7. How can I start with your dashboard and data visualization services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "In order to start with our data visualization service, you just have to submit your requirements and details on the Contact Us form. Our team will then contact you to discuss your business goals and service requirements."
                }
              }]
            }                                                            
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <WhatServiceDo data={whatServiceContent} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
                Our Dashboard and Data  <span class='h1-span'>Visualization Work</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection num={index} lastLength={projectData} data={data} />
              </div>
            ))}
          </section>
          <Testimonials title={'Client’s Review on Data Visualization Services'} />
          <Cta data={cta2} />
          <CommonServiceComponent data={commonContent5} condition mode={"light"} />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq Questions={Questions} section des={faqDes} link faqData={faqData}/>
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="Data Visualization Agency | Data Visualization as a Service" description="Our data visualization agency transforms raw data into visual narratives and drives actionable outcomes for long-term growth." />
)

